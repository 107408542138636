import ClientDropDown from "./ClientDropDown";
import InputTable from "./InputTable";

const NewInvoice = () => {
    return (
        <>
            <ClientDropDown />
            <InputTable />
        </>
    );
}

export default NewInvoice;