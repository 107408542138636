import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Invoices from './components/Invoices';

function App() {
  return (
      <Invoices />
  );
}

export default App;
